import React from "react"

import waltz_roll_mini_1 from "../images/products/waltz_roll_mini_1.jpg"
import waltz_roll_mini_2 from "../images/products/waltz_roll_mini_2.jpg"
import waltz_roll_mini_3 from "../images/products/waltz_roll_mini_3.jpg"
import waltz_roll_mini_4 from "../images/products/waltz_roll_mini_4.jpg"

class WaltzCream extends React.Component {
  render() {
    return (
      <section id="proizvodi" className="hero products-page default-page">
        <div className="container padding-0 margin-0">
          <div className="columns margin-0 main">
            <div className="column is-4 static-part is-left padding-0">
              <div className="content">
                <div className="title">WALTZ ROLL MINI</div>
                <span className="zigzag"></span>
                <p className="text">
                  Dostupno u tri ukusa. Uvek uz Vas, bilo da ste sami ili sa
                  prijateljima. Pet mini rolni u jednom pakovanju učiniće da se
                  osećate sjajno. Otvorite ga i pustite da zabava započne.
                </p>
              </div>
            </div>
            {/*STATIC PART END */}
            <div className="column is-8 content-part is-right padding-0">
              <div className="columns is-multiline inner margin-0">
                <div className="column is-6 single-product padding-0">
                  <img src={waltz_roll_mini_1} alt="Waltz roll mini" />
                </div>
                <div className="column is-6 single-product padding-0">
                  <img src={waltz_roll_mini_2} alt="Waltz roll mini" />
                </div>
                <div className="column is-6 single-product padding-0">
                  <img src={waltz_roll_mini_3} alt="Waltz roll mini" />
                </div>
                <div className="column is-6 single-product padding-0">
                  <img src={waltz_roll_mini_4} alt="Waltz roll mini" />
                </div>
              </div>
            </div>
            {/* PRODUCTS PART END */}
          </div>
          {/* COLUMNS END */}
        </div>
        {/* CONTAINER END */}
      </section> /* PRODUCTS PAGE SECTION END */
    )
  }
}

export default WaltzCream
