import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import Header from "../components/Header"
import WaltzRollMini from "../components/WaltzRollMini"
import Footer from "../components/Footer"

class WaltzRollMiniPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <meta charset="utf-8" />
          <title>WALTZ | Waltz Roll Mini</title>
          <meta
            name="description"
            content="Dostupno u tri ukusa. Uvek uz Vas, bilo da ste sami ili sa
                  prijateljima. Pet mini rolni u jednom pakovanju učiniće da se
                  osećate sjajno. Otvorite ga i pustite da zabava započne."
          />
          <meta
            name="image"
            content="https://waltz.rs/waltz_roll_mini_seo.jpg"
          />
          <meta itemprop="name" content="WALTZ | Waltz Roll Mini" />
          <meta
            itemprop="description"
            content="Dostupno u tri ukusa. Uvek uz Vas, bilo da ste sami ili sa
            prijateljima. Pet mini rolni u jednom pakovanju učiniće da se
            osećate sjajno. Otvorite ga i pustite da zabava započne."
          />
          <meta
            itemprop="image"
            content="https://waltz.rs/waltz_roll_mini_seo.jpg"
          />
          <meta name="og:title" content="WALTZ | Waltz Roll Mini" />
          <meta
            name="og:description"
            content="Dostupno u tri ukusa. Uvek uz Vas, bilo da ste sami ili sa
            prijateljima. Pet mini rolni u jednom pakovanju učiniće da se
            osećate sjajno. Otvorite ga i pustite da zabava započne."
          />
          <meta
            name="og:image"
            content="https://waltz.rs/waltz_roll_mini_seo.jpg"
          />
          <meta name="og:url" content="https://waltz.rs/waltz-roll-mini" />
          <meta name="og:site_name" content="WALTZ | Waltz Roll Mini" />
          <meta name="og:locale" content="sr" />
          <meta name="og:type" content="website" />
        </Helmet>
        <Header />
        <WaltzRollMini />
        <Footer />
      </Layout>
    )
  }
}
export default WaltzRollMiniPage
